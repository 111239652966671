<template>
  <b-container class="vehicle-details">
    <h2 class="text-primary title text-center">{{ $t("ListingProcess.Contact") }}</h2>

    <div class="mt-4">
      <TheListingCard />
    </div>

    <b-alert
      variant="info"
      show
      dismissible
      class="alert-box info-box contact flex-column align-items-start"
    >
      <h5>
        <b-avatar variant="success" size="1.4em">
          <b-icon-shield-check scale="0.8"/>
        </b-avatar>
        <b class="px-2">{{ $t("ListingProcess.ContactWarnTitle") }}</b>
      </h5>
      <p>
        {{ $t("ListingProcess.ContactWarnDesc") }}
      </p>
    </b-alert>

    <TheLocationEditer ref="LocationEditor" />

    <div class="d-flex justify-content-between align-items-center mt-10">
      <div class="d-flex justify-content-between align-items-center">
        <b-button pill variant="half-dark" class="px-3" v-b-modal.cancelModal>
          <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
        </b-button>
      </div>
        <TheSaveAndLeave />
      </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  components: {
    TheListingCard: () => import("@/components/listing/TheListingCard"),
    TheLocationEditer: () => import("@/components/listing/TheLocationEditer"),
    TheSaveAndLeave: () => import("@/components/listing/TheSaveAndLeaveButton"),
  },
  props: {
    nextButton: { type: Function },
  },
  data() {
    return {};
  },
  computed: {
    eligibleContact() {
      if (
        this.$store.getters.vehicleContactFirstName &&
        this.$store.getters.vehicleContactLastName &&
        this.$store.getters.vehicleContactEmail &&
        this.$store.getters.vehicleContactMobile
      ) {
        return true;
      } else return false;
    },
    eligibleLocation() {
      let location = this.$store.getters.vehicleLocation;
      if (location) {
        if (location.streetAddress && location.city && location.region) return true;
        else return false;
      } else return false;
    },
  },
  methods: {
    checkNextButton() {
      if (this.eligibleContact && this.eligibleLocation) {
        this.nextButton("page5", false);
      } else {
        this.nextButton("page5", true);
      }
    },
    async getVehicleDetails() {
      try {
        const response = await axios.post("Listing/CarInfo", {
          ListingId: this.$store.getters.listingStockID
        });
        this.$refs.LocationEditor.autoFillUserInfo(response.data.Contact);
        this.$refs.LocationEditor.autoFilllocationInfo(response.data.Contact);
      } catch (error) {
        console.log(error.data);
      }
    },

  },
  beforeMount() {
    this.checkNextButton();
    this.getVehicleDetails();
  },
  watch: {
    eligibleContact() {
      this.checkNextButton();
    },
    eligibleLocation() {
      this.checkNextButton();
    },
  },
};
</script>
<style scoped lang="scss">
.cancelButton {
  margin-left: 210px;
}
</style>

<style lang="scss">

.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.input-tel__input {
  border-radius: 0px !important;
  border-right: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.select-country-container {
  // pointer-events: none;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.alert-box.contact {
  margin: 64px 0 !important;
}
</style>
